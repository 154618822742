
import Vue from 'vue';
import AtomText from '@/components/atoms/AtomText.vue';

export default Vue.extend({
  name: 'AtomSetup',
  props: {
    content: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  components: { AtomText },
});
