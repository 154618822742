var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('OrganismPageSubHeader',{attrs:{"icon":"icons/icon-arrow-left.svg","title":_vm.$t('settings.notification'),"isIconClickable":""},on:{"click":function($event){return _vm.$router.push({ name: _vm.RouteNamesEnum.SETTINGS })}}}),_c('BContainer',[_c('BRow',[_c('BCol',[_c('AtomText',{attrs:{"tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('settings.notification'))+" ")])],1)],1),(!_vm.isPhoneVerified)?_c('BRow',[_c('BCol',[_c('AtomNotification',{attrs:{"big":""},on:{"click":_vm.openPhoneValidation}},[_vm._v(" "+_vm._s(_vm.$t('global.alerts.phoneNumberValidation'))+" ")])],1)],1):_vm._e(),_c('BRow',[_c('BCol',_vm._l((_vm.notificationsList),function(setting,index){return _c('AtomSetup',{key:index,attrs:{"content":setting.title,"text":setting.description},scopedSlots:_vm._u([(setting.atLeastOnce)?{key:"description",fn:function(){return [_c('AtomNotification',{directives:[{name:"show",rawName:"v-show",value:(
                !_vm.isChanelChecked(_vm.CommunicationChannels.SMS, setting.notificationType, _vm.notificationsConfig)
                && !_vm.isChanelChecked(_vm.CommunicationChannels.E_MAIL, setting.notificationType, _vm.notificationsConfig)
              ),expression:"\n                !isChanelChecked(CommunicationChannels.SMS, setting.notificationType, notificationsConfig)\n                && !isChanelChecked(CommunicationChannels.E_MAIL, setting.notificationType, notificationsConfig)\n              "}],attrs:{"variant":_vm.NotificationVariant.WARNING}},[_vm._v(" "+_vm._s(setting.warningMsg)+" ")])]},proxy:true}:null],null,true)},[_c('div',{staticClass:"controls-wrapper"},[(setting.availableChannels.includes(_vm.CommunicationChannels.SMS))?_c('AtomCheckbox',{attrs:{"id":`${setting.notificationType}_SMS`,"content":"SMS","checked":_vm.isChanelChecked(_vm.CommunicationChannels.SMS, setting.notificationType, _vm.notificationsConfig),"disabled":!_vm.isPhoneVerified},on:{"change":(status) => _vm.toggleChannel(
                _vm.CommunicationChannels.SMS,
                setting.notificationType,
                _vm.notificationsConfig,
                status
              )}}):_vm._e(),(setting.availableChannels.includes(_vm.CommunicationChannels.E_MAIL))?_c('AtomCheckbox',{attrs:{"id":`${setting.notificationType}_Email`,"content":"E-Mail","checked":_vm.isChanelChecked(_vm.CommunicationChannels.E_MAIL, setting.notificationType, _vm.notificationsConfig)},on:{"change":(status) => _vm.toggleChannel(
                _vm.CommunicationChannels.E_MAIL,
                setting.notificationType,
                _vm.notificationsConfig,
                status,
              )}}):_vm._e()],1)])}),1)],1),_c('BRow',[_c('BCol',{staticClass:"button-wrapper"},[_c('AtomButton',{attrs:{"content":_vm.$t('dictionary.save'),"variant":_vm.ButtonVariant.PRIMARY,"disabled":_vm.isDataValid,"is-loading":_vm.isSaving},on:{"click":_vm.updateNotifications}})],1)],1)],1),_c('OrganismPhoneValidation')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }