import { http } from '@/services/http/HTTPService';
import { APIGEE_DOMAIN, NOTIFICATIONS_SERVICE_URL } from '@/common/config';

export const getNotificationsConfig = async (
  userId: string,
): Promise<any> => {
  const options = {
    params: {
      userId,
    },
  };
  const { status, data } = await http.get(
    `${APIGEE_DOMAIN}/${NOTIFICATIONS_SERVICE_URL}/subscriptions`,
    options,
  );

  return {
    status,
    data: data.result.types,
  };
};

export const putNotificationsConfig = async (
  userId: string,
  payload: any,
): Promise<any> => {
  const options = {
    params: {
      userId,
    },
  };
  try {
    const { status, data } = await http.put(
      `${APIGEE_DOMAIN}/${NOTIFICATIONS_SERVICE_URL}/subscriptions`,
      payload,
      options,
    );

    return {
      status,
      data: data.result.types,
    };
  } catch (error) {
    return {
      data: error.response.data,
      status: 400,
    };
  }
};
