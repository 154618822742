
/* eslint-disable @typescript-eslint/camelcase */
import Vue from 'vue';
import { mapGetters } from 'vuex';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomSetup from '@/components/atoms/AtomSetup.vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomCheckbox from '@/components/atoms/AtomCheckbox.vue';
import AtomNotification, { NotificationVariant } from '@/components/atoms/AtomNotification.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';
import OrganismPhoneValidation, { PHONE_VALIDATION_MODAL_ID } from '@/components/organisms/phone-validation/OrganismPhoneValidation.vue';

import { getNotificationsConfig, putNotificationsConfig } from '@/api/notifications/notificationsApi';
import RouteNamesEnum from '@/router/RouteNamesEnum';

enum CommunicationChannels {
  APP = 'App',
  SMS = 'SMS',
  E_MAIL = 'eMail',
}

enum NotificationTypes {
  TRIP_UPDATES = 'RideCreatedUpdatedCancelled',
  INTENT_UPDATES = 'IntentCreatedUpdatedCancelled',
  RIPPLER = 'Rippler',
  COMMUNITY = 'Community',
}
const mappedNotificationConfig = [
  NotificationTypes.TRIP_UPDATES,
  NotificationTypes.INTENT_UPDATES,
  NotificationTypes.RIPPLER,
  NotificationTypes.COMMUNITY,
];

export default Vue.extend({
  name: 'TemplateSettingsSecurity',
  components: {
    AtomText,
    AtomButton,
    AtomCheckbox,
    AtomSetup,
    AtomNotification,
    OrganismPageSubHeader,
    OrganismPhoneValidation,
  },
  data() {
    return {
      ButtonVariant,
      ToastVariants,
      NotificationVariant,
      RouteNamesEnum,
      CommunicationChannels,
      isSaving: false,
      // notificationsList Used to generate the UI elements.
      notificationsList: [
        {
          title: this.$i18n.t(`settings.notifications.${NotificationTypes.TRIP_UPDATES}_title`).toString(),
          description: this.$i18n.t(`settings.notifications.${NotificationTypes.TRIP_UPDATES}_description`).toString(),
          notificationType: NotificationTypes.TRIP_UPDATES,
          atLeastOnce: true,
          warningMsg: this.$i18n.t(`settings.notifications.${NotificationTypes.TRIP_UPDATES}_warning`).toString(),
          availableChannels: [
            CommunicationChannels.E_MAIL,
            CommunicationChannels.SMS,
          ],
        },
        {
          title: this.$i18n.t(`settings.notifications.${NotificationTypes.INTENT_UPDATES}_title`).toString(),
          description: this.$i18n.t(
            `settings.notifications.${NotificationTypes.INTENT_UPDATES}_description`,
          ).toString(),
          notificationType: NotificationTypes.INTENT_UPDATES,
          atLeastOnce: false,
          availableChannels: [
            CommunicationChannels.E_MAIL,
          ],
        },
        {
          title: this.$i18n.t(`settings.notifications.${NotificationTypes.COMMUNITY}_title`).toString(),
          description: this.$i18n.t(`settings.notifications.${NotificationTypes.COMMUNITY}_description`).toString(),
          notificationType: NotificationTypes.COMMUNITY,
          atLeastOnce: false,
          availableChannels: [
            CommunicationChannels.E_MAIL,
          ],
        },
      ],
      // notificationsConfig Used to store the actual channels settings.
      notificationsConfig: [] as any[],
    };
  },
  computed: {
    ...mapGetters('user', ['getUserId', 'getIdentityId', 'getAccessToken', 'getBlockedUsers', 'isPhoneVerified']),
    isDataValid(): boolean {
      const { notificationsList, notificationsConfig, isChanelChecked } = this;
      const settingsWithErr = notificationsList.reduce((acc: typeof notificationsList, next) => {
        if (
          next.atLeastOnce
          && !next
            .availableChannels
            .some(
              (value: CommunicationChannels) =>
                isChanelChecked(value, next.notificationType, notificationsConfig),
            )
        ) {
          acc.push(next);
        }
        return acc;
      }, []);

      return !!settingsWithErr.length;
    },
  },
  methods: {
    isChanelChecked(
      channelType: CommunicationChannels,
      notificationType: NotificationTypes,
      notificationsConfig: any[],
    ): boolean {
      const notification = notificationsConfig.find((n) => n.notificationType === notificationType);
      if (!notification) return false;

      return notification.channels.includes(channelType);
    },
    toggleChannel(
      channelType: CommunicationChannels,
      notificationType: NotificationTypes,
      notificationsConfig: any[],
      checked: boolean,
    ): void {
      const notifications = notificationsConfig.filter((n) => n.notificationType !== notificationType);
      const notification = notificationsConfig.find((n) => n.notificationType === notificationType);
      if (!notification || !notifications) return;

      const { channels } = notification;
      if (checked) {
        notification.channels = [...channels, channelType];
      } else {
        notification.channels = channels.filter((c) => c !== channelType);
      }
      this.notificationsConfig = [...notifications, notification];
    },
    async updateNotifications(): Promise<void> {
      this.isSaving = true;
      const { data, status } = await putNotificationsConfig(this.getUserId, { types: this.notificationsConfig });

      if (status === 200 || status === 201) {
        toast(this.$bvToast, this.$i18n.t('settings.notifications.messages.success').toString());
        this.notificationsConfig = data.filter((n) => mappedNotificationConfig.includes(n.notificationType));
      } else if (status === 400) {
        const textMessage = `
          ${this.$i18n.t(`settings.notifications.${NotificationTypes.TRIP_UPDATES}_title`).toString()}:
          ${this.$i18n.t('settings.notifications.messages.warning').toString()}
        `;
        toast(this.$bvToast, textMessage, ToastVariants.DANGER);
      } else {
        toast(this.$bvToast, this.$i18n.t('settings.notifications.messages.error').toString(), ToastVariants.DANGER);
        this.setConfig();
      }
      this.isSaving = false;
    },
    async setConfig(): Promise<void> {
      const { data } = await getNotificationsConfig(this.getUserId);
      this.notificationsConfig = data.filter((n) => mappedNotificationConfig.includes(n.notificationType));
    },
    openPhoneValidation() {
      this.$bvModal.show(PHONE_VALIDATION_MODAL_ID);
    },
    closePhoneValidation() {
      this.$bvModal.show(PHONE_VALIDATION_MODAL_ID);
    },
  },
  async mounted() {
    this.setConfig();
  },
});
